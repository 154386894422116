import { template as template_3428b8f0e3a248ac9077dfee6fdd2291 } from "@ember/template-compiler";
const FKText = template_3428b8f0e3a248ac9077dfee6fdd2291(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
