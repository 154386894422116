import { template as template_87555eaa77344ec2b9066a1069197d87 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_87555eaa77344ec2b9066a1069197d87(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_87555eaa77344ec2b9066a1069197d87(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_87555eaa77344ec2b9066a1069197d87(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
