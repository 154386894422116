import { template as template_426c80cda5954e94a9f84876b1d28364 } from "@ember/template-compiler";
const FKControlMenuContainer = template_426c80cda5954e94a9f84876b1d28364(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
