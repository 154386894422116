import { template as template_411166f68c8645df83be3bcf770f768b } from "@ember/template-compiler";
const WelcomeHeader = template_411166f68c8645df83be3bcf770f768b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
